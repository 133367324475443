
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'

  export default {
    components: {
      VueSlickCarousel,
    },
    props: {
      blok: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        tabIndex: 0,
        settings: {
          arrows: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          draggable: true,
          swipe: true,
          dots: true,
          arrows: false
        }
      }
    },
    computed: {
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      }
    },
    methods: {
      tabTo(index) {
        this.tabIndex = index
        this.$refs.carousel.goTo(index)
      }
    }
  }
